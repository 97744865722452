/**
 * Note:
 * This file should be synced with the corresponding layout in Contenful CMS.
 */
import { Breadcrumbs } from '@thd-olt-component-react/breadcrumbs';
import { CalloutCard } from '@thd-olt-component-react/callout_card';
import { ContentAccordion } from '@thd-olt-component-react/content-accordion';
import { CapabilityCard } from '@thd-olt-component-react/capability_card';
import {
  params,
  arrayOf,
  alias,
  bool,
  string,
  fragment,
  shape,
  extend,
  customType
} from '@thd-nucleus/data-sources';
import { Hero, HeroPersonalized } from '@thd-olt-component-react/hero';
import { EmtLinks } from '@thd-olt-component-react/emt-links';
import { Endcap } from '@thd-olt-component-react/endcap';
import { MedioInline, PiccolaPencil } from '@thd-olt-component-react/internal-marketing-banner';
import { NewspaperModule } from '@thd-olt-component-react/newspaper-module';
import { BrowseSearchMetadata } from '@thd-olt-component-react/metadata';
import { RelatedSearch } from '@thd-olt-component-react/related-search';
import { Resource } from '@thd-olt-component-react/resource';
import { ContentGalleryCard } from '@thd-olt-component-react/content-gallery-card';
import { SpecialBuyBanner } from '@thd-olt-component-react/special-buy-banner';
import { SideNavigation } from '@thd-olt-component-react/side-navigation';
import { SponsoredBanner } from '@thd-olt-component-react/sponsored-content';
import { Spotlight, SpotlightContainer } from '@thd-olt-component-react/spotlight';
import { PromoVisualNavigation, VisualNavigation } from '@thd-olt-component-react/visual-navigation';
import { ProductShelf } from '@thd-olt-component-react/product-shelf';
import { LandingPageRedirector, LandingPageSearchRedirector } from '@thd-nucleus/app-render';
import {
  DynamicComponentSelector, DynamicSlot,
  HeroComponentSelector, HeroSlot,
  ResourceComponentSelector, ResourceSlot,
  SpotlightComponentSelector, SpotlightSlot
} from './personalization';
import {
  educationalLayoutFragment,
  navigationalLayoutFragment,
  inspirationalLayoutFragment,
  universalLayoutFragment
} from '../templates/dataModel';
import { EducationalLayout } from '../templates/EducationalLayout';
import { NavigationalLayout } from '../templates/NavigationalLayout';
import { InspirationalLayout } from '../templates/InspirationalLayout';
import { FlexibleLayout } from '../templates/FlexibleLayout';

export const previewDataModel = extend(
  {
    educationalPreview: alias('layout').params({
      id: string().isRequired(),
      layoutClass: customType('LayoutClass').enum(['EducationalLayout'])
    }).shape({
      EducationalLayout: educationalLayoutFragment
    })
  },
  {
    navigationalPreview: alias('layout').params({
      id: string().isRequired(),
      layoutClass: customType('LayoutClass').enum(['NavigationalLayout'])
    }).shape({
      NavigationalLayout: navigationalLayoutFragment
    })
  },
  {
    inspirationalPreview: alias('layout').params({
      id: string().isRequired(),
      layoutClass: customType('LayoutClass').enum(['NavigationalLayout'])
    }).shape({
      InspirationalLayout: inspirationalLayoutFragment
    })
  },
  {
    flexiblePreview: alias('layout').params({
      id: string().isRequired(),
      layoutClass: customType('LayoutClass').enum(['UniversalLayout'])
    }).shape({
      UniversalLayout: universalLayoutFragment
    })
  },
  // you need this here too because the query is asked for at the root level
  CalloutCard,
  CapabilityCard,
  ContentAccordion,
  ContentGalleryCard,
  DynamicComponentSelector,
  DynamicSlot,
  EmtLinks,
  Endcap,
  Hero,
  HeroComponentSelector,
  HeroPersonalized,
  HeroSlot,
  LandingPageRedirector,
  LandingPageSearchRedirector,
  MedioInline,
  NewspaperModule,
  PiccolaPencil,
  ProductShelf,
  PromoVisualNavigation,
  RelatedSearch,
  Resource,
  ResourceComponentSelector,
  ResourceSlot,
  SideNavigation,
  SpecialBuyBanner,
  SponsoredBanner,
  Spotlight,
  SpotlightContainer,
  SpotlightComponentSelector,
  SpotlightSlot,
  VisualNavigation,
);
